import React from 'react';
import classes from './Footer.module.scss'
import { NavLink } from 'react-router-dom'

import cymMonkey from '../../../../assets/img/layout/cym_monkey.svg'


import Map from './Map'

const Footer = () => {
    return (
        <React.Fragment>
            <section className={classes.bottom}></section>  
            <footer>
                <Map />
                <div className={classes.footerContainer}>
                    <div className={classes.footerGrid}>
                        <nav>
                            <ul>
                                <li><NavLink to="/" exact activeClassName={classes.active}>Home</NavLink></li>
                                <li><NavLink to="/projects" activeClassName={classes.active}>Projects</NavLink></li>
                                <li><NavLink to="/inventory" activeClassName={classes.active}>Inventory</NavLink></li>
                                {/* <li><NavLink to="/about" activeClassName={classes.active}>About</NavLink></li> */}
                                <li><NavLink to="/contact" activeClassName={classes.active}>Contact</NavLink></li>
                            </ul>
                        </nav>
                        <img src={cymMonkey} alt="CYM Lighting" />
                        <div>
                            72133 Corporate Way<br />
                            Thousand Palms CA 92276<br />
                            &copy; 2020 CYM Lighting
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>

    );
};

export default Footer;