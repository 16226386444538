import React, { useContext, useState } from 'react';
import { withBreakpoints } from 'react-breakpoints'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import { NavLink } from 'react-router-dom'
import './ProjectNav.scss'
import { Context } from '../../../store/Store'

const ProjectNav = props => {

    const [, dispatch] = useContext(Context)
    const { breakpoints, currentBreakpoint } = props
    const [subNavState, setSubNavState] = useState(false)
    const subNavClass = ['subNav']

    const toggleSubNav = () => {
        setSubNavState(!subNavState)
    }

    const setProjectCategory = (category) => {
        dispatch({type: 'SET_PROJECT_CATEGORY', category: category})
    }

    const navLinks = props.sections.map((item, key) => {
        let to = `/projects/${item.link}`
        return <NavLink key={key} to={to} onClick={() => setProjectCategory(item.link)}>{item.display}</NavLink>
    })

    document.body.style.overflow = "auto"
    if(subNavState) { 
        subNavClass.push('on')
        document.body.style.overflow = "hidden"
    }

    const nav = breakpoints[currentBreakpoint] > breakpoints.tabletLandscape 
    ? navLinks 

    :  <div className="mobileNav" onClick={toggleSubNav}>
        <button><FontAwesomeIcon icon={faFilter} className="icon" /> Filter Projects</button>
        <div className={subNavClass.join(' ')}>{navLinks}</div>
       </div>
         
    
    return (
        <React.Fragment>
            <nav className="projectNav">
                <svg preserveAspectRatio="none" width="0" height="0">
                    <clipPath id="navMask" clipPathUnits="objectBoundingBox">
                        <polygon points="100,100 5,100 0,0 100,0 " />
                    </clipPath>
                </svg>
                {nav}
            </nav>
        </React.Fragment>

    )
}

export default withBreakpoints(ProjectNav)