import React, { useContext, useEffect, useCallback } from 'react'
import ImageSection from './ImageSection'
import { Context } from '../../store/Store'
import { ImageOrder } from '../../Shared/Utility'

const ProjectView = props => {
	const [state, dispatch] = useContext(Context)
	const stableDispatch = useCallback(dispatch, [])

	useEffect(() => {
		const imageArray = []

		props.images.images.forEach((obj, key) => {
			obj.images.forEach(image => {
				imageArray.push({ ...image, directory: obj.directory })
			})
		})
		stableDispatch({
			type: 'PROJECT_IMAGES',
			imageArray: imageArray,
			projectDirectory: props.images.project,
		})
	}, [props.images.images, props.images.project, stableDispatch])

	const showLightbox = (image, video) => {
		const { previousImage, currentImage, isVideo, nextImage } = ImageOrder(
			image,
			state.imageArray,
			state.projectDirectory
		)
		document.body.style.overflow = 'hidden'
		dispatch({
			type: 'PROJECT_THUMB_CLICK',
			imgNav: {
				previousImage: previousImage,
				currentImage: currentImage,
				nextImage: nextImage,
				isVideo: isVideo,
			},
		})
	}

	const images = props.images.images.map((obj, key) => {
		return (
			<ImageSection
				key={key}
				{...obj}
				project={props.images.project}
				lbClick={showLightbox}
			/>
		)
	})

	return (
		<React.Fragment>
			<div className="thumbContainer">{images}</div>
		</React.Fragment>
	)
}

export default ProjectView
