import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import classes from './Navigation.module.scss'
import NavigationItems from './NavigationItems'
import { Transition } from 'react-transition-group'
import SideDrawer from './SideDrawer'
import cymLogo from '../../../assets/img/layout/cym_logo.svg'

const Navigation = () => {

    const [menuState, setMenuState] = useState(false)
    const [loading, setLoading] = useState(false)

    const menuOpen = () => {
        return setMenuState(currentState => !currentState)
    }

    useEffect(() => {
        setLoading(true)
    }, [])

    const hamburgerClass = [classes.nav_icon1]
    const menuClass = [classes.sideDrawer]
    let showLightbox = false

    document.body.style.overflow = "auto"
    
    if(menuState) {
        hamburgerClass.push(classes.open)
        menuClass.push(classes.open)
        showLightbox = true
        document.body.style.overflow = "hidden"
    }
    const transitionStyles = {
        entering: { opacity: 0 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    }  
    
    return (
        <React.Fragment>
            <Transition in={loading} timeout={500}>
            {state => (
                <nav style={{...transitionStyles[state]}}
                    className={classes.topNav}>
                    <div className={classes.logo}>
                        <div onClick={menuOpen} className={hamburgerClass.join(' ')}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <Link to="/"><img src={cymLogo} alt="CYM Lighting" /></Link>
                    </div>
                <NavigationItems class={classes.topNav} />
                
                </nav>
            )}                
            </Transition>

            <SideDrawer 
                drawerClass={classes.sideDrawer}
                show={showLightbox}
                clicked={menuOpen} />
        </React.Fragment>
        
    )
}

export default Navigation