const Reducer = (state, action) => {
    switch(action.type) {
        case 'SET_FULL_PROJECTS' :
            return {
                ...state,
                fullProjects: action.full,
                filteredProjects: action.filtered,
                projectView: action.projectView
            }
        case 'SET_FILTERED_PROJECTS' : 
            return {
                ...state,
                filteredProjects: action.projects,
                projectTitle: action.title
            }
        case 'SET_PROJECT_TITLE' :
            return {
                ...state,
                projectTitle: action.title
            }
        case 'SET_PROJECT_CATEGORY' :
            return {
                ...state,
                projectView: 'category',
                projectCategory: action.category

            }
        case 'SET_PROJECT' :
            return {
                ...state, 
                projectView: 'project',
                project: action.project
            }
        case 'RESET_PROJECT_VIEW' :
            return {
                ...state,
                projectView: 'all'
            }
        case 'PROJECT_THUMB_CLICK' :
            return {
                ...state,
                showLightbox: true,
                ...action.imgNav
            }
            case 'SLIDE_CLICK' :
                return {
                    ...state,
                    ...action.imgNav
                }            
        case 'CLOSE_LIGHTBOX' :
            return {
                ...state,
                showLightbox: false
            }
        case 'PROJECT_IMAGES' :
            return {
                ...state, 
                imageArray: action.imageArray,
                projectDirectory: action.projectDirectory
            }

        default :
            return state
    }
}
export default Reducer