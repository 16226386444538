import React from 'react';
import '../../../styles/projectShared.scss'
import ImageLoader from '../../UI/ImageLoader/ImageLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

const ImageThumb = props => {
    
    return (
        <figure className="imageThumb">
            {(props.video) ? <div className="playHolder"><FontAwesomeIcon icon={faPlay} className="playIcon" /></div> : ''}

                <ImageLoader
                    holder="thumbHolder"
                    tiny={require(`../../../assets/img/portfolio/${props.project}/${props.directory}/thumbs/tiny_${props.image}`)}
                    normal={require(`../../../assets/img/portfolio/${props.project}/${props.directory}/thumbs/${props.image}`)}
                    tinyClass="thumbTiny"
                    normalClass="thumbNormal"
                    title={props.image}
                    loadedClass="loaded"
                    unloadedClass="unloaded"
                    loaderClass="spinner"
                    click={() => props.clicked(props.image, props.video)}
                />                
        </figure>
    )
}

export default ImageThumb;