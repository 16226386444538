import React from 'react';
import Lightbox from '../Lightbox/Lightbox'
import NavigationItems from './NavigationItems'
import { Transition } from 'react-transition-group';

const SideDrawer = props => {
    const transitionStyles = {
        entering: { left: '-100%' },
        entered:  { left: '0px' },
        exiting:  { left: '-100%' },
        exited:  { left: '-100%' },
    }

    return (
        <React.Fragment>
            <Transition
                in={props.show}
                timeout={250}
                duration={250}
                mountOnEnter
                unmountOnExit >
            {state => (
                <NavigationItems 
                    style={{...transitionStyles[state]}} 
                    class={props.drawerClass}
                    clicked={props.clicked} />
            )}
            </Transition>                
                
            <Lightbox show={props.show} duration={250} zIndex="75" clicked={props.clicked} />
        </React.Fragment>
    )
}
export default SideDrawer