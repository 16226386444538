import React, { useContext } from 'react';
import CategoryThumb from './CategoryThumb/CategoryThumb'
import { Context } from '../../store/Store'

const CategoryView = props => {

    const [, dispatch] = useContext(Context)

    const setProject = project => {
        dispatch({type: 'SET_PROJECT', project: project})
    }

    const images = props.projects.map(project => {
        return <CategoryThumb 
            {...project}
            clicked={setProject}
            key={project.id} />
    })

    return <div className="projectsContainer">{images}</div>
}

export default CategoryView