export const ImageOrder = (image, imageArray, projectDirectory) => {

    const currentKey = imageArray.findIndex(item => item.image === image)
    const nextKey = (currentKey + 1) % imageArray.length
    const previousKey = (currentKey + imageArray.length - 1) % imageArray.length
    const currentFile = (imageArray[currentKey].video) ? imageArray[currentKey].video : imageArray[currentKey].image
    const isVideo = (imageArray[currentKey].video) ? true : false
    

    return {
        previousImage: `${imageArray[previousKey].image}`,
        currentImage: `/img/portfolio/${projectDirectory}/${imageArray[currentKey].directory}/${currentFile}`,
        isVideo: isVideo,
        nextImage: `${imageArray[nextKey].image}`
    }
}