import React from 'react';
import './ContentWrapper.scss'
import { withBreakpoints } from 'react-breakpoints'


const ContentWrapper = props => {

    const sectionClass = ['contentWrapper']
    sectionClass.push(props.color)
    sectionClass.push(props.page)

    const { breakpoints, currentBreakpoint } = props

    const letter = (breakpoints[currentBreakpoint] > breakpoints.tabletLandscape)
    ? <React.Fragment>
        <div className="letterContainer">
            <img src={props.img} className='letter' alt="letter" />
        </div>
    </React.Fragment>
    
    : null

    return (
        <React.Fragment>
        <section className={sectionClass.join(' ')}>
            {letter}
            <div className="headingContainer">
                <h1>{props.page}</h1>
            </div>               
            <div className="container">
                {props.children}
            </div>            
        </section>
        </React.Fragment>

    )
}

export default  withBreakpoints(ContentWrapper)
