import React from 'react'

const Map = props => {
    return (
    <iframe
        title="CYM Map"
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ14KO97vi2oARpie9kSejuoY&key=AIzaSyAjes3CVGvjzWnMIjMqmhZVmlZt-1NxAL4&zoom=15" 
        allowFullScreen>
    </iframe>
    )
}

export default Map