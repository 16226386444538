import React, { useContext } from 'react'
import { Context } from '../../../store/Store'

import FullImageView from '../FullImageView/FullImageView'
import Lightbox from '../../UI/Lightbox/Lightbox'
import { ImageOrder } from '../../../Shared/Utility'

const ProjectLightBox = () => {

    const [state, dispatch] = useContext(Context)

    const closeLightbox = () => {
        dispatch({type: 'CLOSE_LIGHTBOX'})
        document.body.style.overflow = "auto"
    }
    const imageSlide = direction => {
        const {previousImage, currentImage, isVideo, nextImage} = ImageOrder(state[direction], state.imageArray, state.projectDirectory)
        dispatch({type: 'SLIDE_CLICK', imgNav: {previousImage: previousImage, currentImage: currentImage, nextImage: nextImage, isVideo: isVideo}})
    }

    return (
        <React.Fragment>
            <FullImageView show={state.showLightbox} image={state.currentImage} isVideo={state.isVideo} duration={550} slideClick={imageSlide} closeClicked={closeLightbox} />
            <Lightbox show={state.showLightbox} duration={250} zIndex="600" clicked={closeLightbox} />
        </React.Fragment>
    );
};

export default ProjectLightBox;