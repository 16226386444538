import React from 'react'
import Navigation from '../Navigation/Navigation'
import classes from './Layout.module.scss'
import Footer from './Footer/Footer'
import ProjectLightBox from '../../Projects/ProjectLightBox/ProjectLightBox'

const Layout = props => {
     

    return (
        <React.Fragment>
            <header>
                <Navigation />
            </header>
            
            <main className={classes.mainContent}>
                {props.children}
            </main>
            
            <ProjectLightBox />
            <Footer />


        </React.Fragment>
    )
}

export default Layout;