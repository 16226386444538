import React, { useState, useEffect } from 'react';
import classes from './FullImageView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleDoubleLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Transition, TransitionGroup, CSSTransition } from 'react-transition-group'
import BarLoader from "react-spinners/BarLoader"

const FullImageView = props => {

    const transitionStyles = {
        entering: { left: '-100%' },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    }

    const [loaded, setLoaded] = useState(false)
    let imageClass = classes.loading
    if(loaded) { imageClass = classes.loaded }

    useEffect(() => {
        setLoaded(false)
    }, [props.image])

    const onLoad = () => {
        setLoaded(true)
    }


    const src = (props.image) ? require(`../../../assets${props.image}`) : null 
    const altSrc = (props.isVideo) ? require(`../../../assets${props.image.replace('.mp4', '.ogg')}`) : null
    const media = (props.isVideo) 
        ? 
            <video controls>
                <source src={src} type="video/mp4" />
                <source src={altSrc} type="video/ogg" />
                Your browser does not support the video tag.
            </video>            
        :  
            <div className={classes.wrapper}>
            <BarLoader
                loading={!loaded}
                css={{

                }}
                width={'50%'}
                height={2}
                color={"#ff9d57"}
            />                 
                <img src={src} className={imageClass} alt="Full View" onLoad={onLoad} />
            </div>

    return (
        <Transition 
            in={props.show}
            timeout={props.duration}
            duration={props.duration}
            mountOnEnter
            unmountOnExit
            >
            {state => (
                <div className={classes.imageViewContainer} style={{...transitionStyles[state]}}>
                    <div className={classes.navContainer}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} className={classes.icon} onClick={() => props.slideClick('previousImage')} />
                        <FontAwesomeIcon icon={faTimes} className={classes.icon} onClick={props.closeClicked} />
                        <FontAwesomeIcon icon={faAngleDoubleRight} className={classes.icon} onClick={() => props.slideClick('nextImage')} />
                    </div>
                    <div className={classes.imageContainer}>
                        <TransitionGroup component={null}>
                        <CSSTransition
                            key={props.image}
                            appear
                            timeout={520}
                            classNames={{ ...classes }}>

                            {media}
                               
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>                
            )}
        </Transition>        
        
    )
};

export default FullImageView;