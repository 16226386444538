import React, { useState } from 'react'
import BarLoader from "react-spinners/BarLoader"

const ImageLoader = props => {
    
    const [loadedState, setLoaded] = useState(false) 
    const tinyClass = [props.tinyClass]
    const normalClass = [props.normalClass]
    const loaderClass = [props.loaderClass]  
    const holderClass = [props.holder] 

    if(loadedState) {
        loaderClass.push(props.unloadedClass)
        tinyClass.push(props.unloadedClass)
        normalClass.push(props.loadedClass)
        holderClass.push(props.loadedClass)
    }

    return (
        <div className={holderClass.join(' ')}>
            <BarLoader
                loading={!loadedState}
                css={{
                    margin: '0 auto',
                    zIndex: 5,
                    position: 'absolute'
                }}
                width={'100%'}
                height={2}
                color={"#ff9d57"}
            />            
            <img 
                src={props.tiny} 

                className={tinyClass.join(' ')} 
                alt={props.title} />

            <img 
                src={props.normal} 

                className={normalClass.join(' ')} 
                alt={props.title}
                onLoad={(() => setLoaded(true))}
                onClick={props.click} /> 
        </div>
    );
};

export default ImageLoader;