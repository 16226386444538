import React from 'react';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import Letter from '../../assets/img/layout/large_y.svg'


const Inventory = props => (
    <ContentWrapper 
        color="light"
        page="inventory"
        img={Letter}>


    <div className="contentContainer"><h3 style={{marginLeft: '50px'}}>Coming Soon!</h3></div>
    
    </ContentWrapper> 
)

export default Inventory