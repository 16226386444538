import React from 'react';
import { Transition } from 'react-transition-group';
import classes from './Lightbox.module.scss'

const Lightbox = props => {
    const transitionStyles = {
        entering: { left: '-100%' },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
      }

    return (
        <Transition 
            in={props.show}
            timeout={props.duration}
            duration={props.duration}
            mountOnEnter
            unmountOnExit
            >
            {state => (
            <div
                style={{...transitionStyles[state], zIndex: props.zIndex}} 
                className={classes.lightbox}
                onClick={props.clicked}></div>
            )}
        </Transition>
        
    )
}

export default Lightbox;