import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import axios from 'axios'

import classes from './ContactForm.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'

const ContactForm = () => {

    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'all',
    })

    const [sendState, setSendState] = useState(false)
    const [message, setMessage] = useState(null)

    const messageClass = [classes.formMessage]
    if(message) { messageClass.push(classes.show)}


    const onSubmit = data => {
        setSendState(true)

        const formData = new FormData()
        for(let key in data) {
            formData.append(key, data[key])
        }
        
        axios.post('http://cymlighting.com/api/send.php', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(function (response) {

            if(response.data) {
                setSendState(false)
                setMessage('Thank You! Someone Will Get Back With You Shortly.')
                reset()
            }
            if(!response.data) {
                setSendState(false)
                setMessage('Oh No! We\'ve Run Into An Error. Please Try Again Later.')
                reset()
            }            
        })        
        .catch(error => {
            console.log(error)
        })
    }

    let submitButton = <button type="submit"><FontAwesomeIcon icon={faPaperPlane} /> Send Message</button>

    if(Object.keys(errors).length !== 0) {
        submitButton = <button type="submit" disabled><FontAwesomeIcon icon={faTimesCircle} /> Send Message</button>
    }

    if(sendState) {
        submitButton = <button type="submit"><FontAwesomeIcon icon={faSpinner} spin /> Sending Message</button>
    }

    return (
        <div className={classes.form}>
            <h4>Send Us A Message</h4>
            <span className={messageClass.join(' ')}>{message}</span>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.name}>
                    <label>Name {errors.name && <span>Please Enter Your Name</span>}</label>
                    <input 
                        name="name" 
                        placeholder="Name" 
                        type="text" 
                        ref={register({ required: true })}
                        aria-invalid={errors.name ? "true" : "false"}
                        className={errors.name ? classes.error : null} />            
                        
                </div>
                <div className={classes.email}>
                    <label>Email
                        {errors.email?.type === 'required' && <span> Please Enter Your Email Address</span>}
                        {errors.email?.type === 'pattern' && <span> Please Enter A Valid Email Address</span>}
                    </label>
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="Email Address" 
                        ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })} 
                        aria-invalid={errors.email ? "true" : "false"}
                        className={errors.email ? classes.error : null} />
                </div>
                <div className={classes.textarea}>
                    <label>Message {errors.message && <span>Please Enter Your Message</span>}</label>
                    <textarea 
                        name="message" 
                        placeholder="Message" 
                        rows="10" 
                        ref={register({ required: true })}
                        aria-invalid={errors.message ? "true" : "false"}
                        className={errors.message ? classes.error : null}></textarea>
                </div>
                <div className={classes.submit}>
                    {submitButton}
                </div>
            </form>
    </div>
    );
};

export default ContactForm;