import React from 'react'
import {Helmet} from "react-helmet"
import { withRouter } from 'react-router-dom'

const HelmetWrapper = props => {
    
    let url = `http://cymlighting.com${props.history.location.pathname}`
    let image = (props.image) ? `http://cymlighting.com${props.image}` : ''


    return (
        <Helmet>
            {(props.title) && <title>{props.title}</title>}
            {props.title && <meta property="og:title" content={props.title} />}
            {props.description && <meta property="og:description" content={props.description} />}
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />

            {props.title && <meta name="twitter:title" content={props.title} />}
            {props.description && <meta name="twitter:description" content={props.description} />}
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary_large_image" />                    
    </Helmet>
    )
}

export default withRouter(HelmetWrapper)