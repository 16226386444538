import React from 'react'
import ImageLoader from '../../UI/ImageLoader/ImageLoader'
import '../../../styles/projectShared.scss'

import { NavLink } from 'react-router-dom'

const CategoryThumb = props => {
	console.log(props)
	return (
		<NavLink to={`/projects/${props.section}/${props.url}/`}>
			<figure
				className="categoryThumb"
				onClick={() => props.clicked(props.url)}
			>
				<ImageLoader
					holder="categoryThumbHolder"
					tiny={require(`../../../assets/img/portfolio/${props.url}/cover_tiny.jpg`)}
					normal={require(`../../../assets/img/portfolio/${props.url}/cover.jpg`)}
					tinyClass="thumbTiny"
					normalClass="thumbNormal"
					title={props.title}
					loadedClass="loaded"
					unloadedClass="unloaded"
					loaderClass="spinner"
				/>

				<figcaption>
					<span>{props.title}</span>
					<div className="line"></div>
				</figcaption>
			</figure>
		</NavLink>
	)
}

export default CategoryThumb
