import React, { useEffect, useContext } from 'react';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import axios from '../../axios'
import Letter from '../../assets/img/layout/large_c.svg'
import classes from './Projects.module.scss';
import '../../styles/projectShared.scss'
import ProjectNav from '../../components/Projects/ProjectNav/ProjectNav'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CategoryView from '../../components/Projects/CategoryView'
import ProjectView from '../../components/Projects/ProjectView'
import { Context } from '../../store/Store'
import imageJSON from '../../assets/img/gallery'
import BarLoader from 'react-spinners/BarLoader'
import HelmetWrapper from '../../HOC/Helmet/HelmetWrapper'

const Projects = props => {
 
    const [state, dispatch] = useContext(Context)

    const section = (props.match.params.section !== 'all') ? props.match.params.section : undefined
    
    const project = props.match.params.project
    const transitionEnded = props.transitionEnded

    let projects = <BarLoader
        css={{
            margin: '0 auto',
        }}
        width={'50%'}
        height={2}
        color={"#ff9d57"}
/>
    let projectTitle = ''
    let projectMetaImage = ''    

    const projectCategories = [
        {link: 'all', display: 'View All'},
        {link: 'corporate', display: 'Corporate'},
        {link: 'awards', display: 'Awards & Galas'},
        {link: 'television', display: 'Television'},
        {link: 'entertainment', display: 'Entertainment'},
        {link: 'custom', display: 'Custom Structures'},
    ]    

    const getMetaImage = haystack => {
        if(typeof haystack === 'object' && haystack !== null) {
            for(let i = haystack.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i)
                const temp = haystack[i]
                haystack[i] = haystack[j]
                haystack[j] = temp
            }
            return require(`../../assets/img/portfolio/${haystack[0].url}/cover.jpg`)            
        } else {
            return require(`../../assets/img/portfolio/${haystack}/cover.jpg`)
        }
        

    }
    
    useEffect(() => {
        //first time loading the projects page
        // if(!state.fullProjects && transitionEnded)
        if(!state.fullProjects)
        {         
            axios.get('/projects.json')
            .then(res => {
                const fetchedProjects = []

                for(let key in res.data) {
                    fetchedProjects.push({
                        ...res.data[key],
                        id: key
                    })
                }
                let view = 'all'
                
                if(section && !project) { view = 'category' }
                if(section && project) { view = 'project'}
                
                dispatch({type: 'SET_FULL_PROJECTS', full: fetchedProjects, filtered: fetchedProjects, projectView: view })
            })
        }

        if((state.projectView === 'project' && !project) || (state.projectView === 'category' && !section) ) {
            dispatch({type: 'RESET_PROJECT_VIEW'})
        }

    }, [transitionEnded, dispatch, state.fullProjects, section, project, state.projectView])


    if(state.projectView === 'all')
    {
        projects = <CategoryView projects={state.filteredProjects} />
        projectTitle = 'All'
        projectMetaImage = getMetaImage(state.filteredProjects)
        
    }
    
    if(state.projectView === 'category' && section) {
        const filtered = state.fullProjects.filter(item => item.section === section)
        const titleFiltered = projectCategories.find(item => item.link === section)
        projectTitle = titleFiltered.display
        projects = <CategoryView projects={filtered} />
        projectMetaImage = getMetaImage(filtered)
    }
    if(state.projectView === 'project' && project) {
        const projectImages = imageJSON.find(item => item.project === project)

        projectTitle = projectImages.display
        projects = <ProjectView images={projectImages} /> 
        projectMetaImage = getMetaImage(projectImages.project)

    }

    return (

        <ContentWrapper 
            color="dark"
            page="projects"
            img={Letter}>

        <HelmetWrapper 
                title={`CYM Lighting » Projects » ${projectTitle}`}
                image={projectMetaImage}
                description="Full Service Production Lighting"
                />                
                
            <ProjectNav sections={projectCategories} />

            <TransitionGroup className={classes.titleContainer}>
                <CSSTransition
                    key={projectTitle}
                    timeout={300}
                    classNames={{
                        enter: classes.titleEnter,
                        enterActive: classes.titleEnterActive,
                        exit: classes.titleExit,
                        exitActive: classes.titleExitActive,
                    }}>
                        <h2>{projectTitle}</h2>
                    </CSSTransition>
            </TransitionGroup>
            
            <div className="contentContainer">
                <TransitionGroup component={null}>
                    <CSSTransition
                        appear
                        key={props.location.key}
                        timeout={520}
                        classNames={{ ...classes }}>
                        <div>{projects}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </ContentWrapper>
    )
     
}

export default Projects