import React from 'react';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper'
import classes from './Contact.module.scss'
import VCard from '../../components/Contact/VCard/VCard'
import ContactForm from '../../components/Contact/ContactForm/ContactForm'
import Letter from '../../assets/img/layout/large_m.svg'
import HelmetWrapper from '../../HOC/Helmet/HelmetWrapper'

const Contact = props => {

    const containerClass = [classes.contactGrid, "contentContainer"]

    return (
        
        <ContentWrapper 
            color="dark"
            page="contact"
            img={Letter}>

            <HelmetWrapper 
                title={`CYM Lighting » Contact`}
                description="Full Service Production Lighting"
            />                
            
        <div className={containerClass.join(' ')}>
            <VCard
                department="Sales & Rentals"
                name="Kevin Swank"
                office="(760) 343-1965 X23"
                cell="(760) 835-5713" />
            
            <VCard
                department="Administration"
                name="Colleen Swank"
                office="(760) 343-1965 X21"
                cell="(760) 835-3684" />            

            <ContactForm />

        </div>

        </ContentWrapper> 
    )
    
}

export default Contact