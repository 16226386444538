import React from 'react'
import BarLoader from 'react-spinners/BarLoader'
import './Loading.scss'

const Loading = props => {

    const divClass = ['loading']
    divClass.push(props.stage)
    return (
        <div className={divClass.join(' ')}>
            <div className="loadingContainer">
                <h1 className='loading'>LOADING</h1>
                <BarLoader
                    
                    css={{
                        zIndex: 1,
                        position: 'absolute'
                    }}
                    width={'100%'}
                    height={10}
                    color={"#ff9d57"}
                />    
            </div> 
        </div>
    );
};

export default Loading;