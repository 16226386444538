import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMobile, faUser } from '@fortawesome/free-solid-svg-icons'
import classes from './VCard.module.scss'

const VCard = props => {
    return (
        <div className={classes.card}>
            <h4>{props.department}</h4>
            <div className={classes.cardGrid}>
                <FontAwesomeIcon icon={faUser} size="lg" /> 
                <span>{props.name}</span>

                <FontAwesomeIcon icon={faPhone} size="lg" /> 
                <span>Office: {props.office}</span>
                
                <FontAwesomeIcon icon={faMobile} size="lg" /> 
                <span>Cell: {props.cell}</span>
            </div>
        </div>
    );
};

export default VCard;