import React from 'react';
import ImageThumb from './ImageThumb/ImageThumb'
import '../../styles/projectShared.scss'


const ImageSection = props => {
 
    const images = props.images.map((img, key) => {
        return <ImageThumb image={img.image} key={key} {...props} clicked={props.lbClick} video={img.video} />
    })
    const heading = (props.title !== '') ? <h3>{props.title}</h3> : ''

    return (
        <React.Fragment>
            {heading}
            <div className="projectsContainer">{images}</div>
        </React.Fragment>
        
    )
}
export default ImageSection