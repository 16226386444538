import React from 'react'
import NavigationItem from './NavigationItem'

const NavigationItems = props => {
	const nav = [
		{ to: '/projects', text: 'Projects' },
		{ to: '/inventory', text: 'Inventory' },
		{ to: '/contact', text: 'Contact' },
	]

	const navLinks = nav.map(link => {
		return (
			<NavigationItem
				key={link.text}
				url={link.to}
				clicked={props.clicked}
			>
				{link.text}
			</NavigationItem>
		)
	})

	return (
		<ul className={props.class} style={props.style}>
			{navLinks}
			<li>
				<a href="https://www.thesocialsanctuary.com/" target="_new">
					SOCIAL SANCTUARY
				</a>
			</li>
		</ul>
	)
}

export default NavigationItems
