import React, { useState, useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { withBreakpoints } from 'react-breakpoints'
import HelmetWrapper from '../../HOC/Helmet/HelmetWrapper'

import './Home.scss'
import classes from './Home.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

const Home = props => {

    const [slideState, setSlideState] = useState(0)
    const [menuState, setMenuState] = useState(false)
    const { breakpoints, currentBreakpoint } = props
    const menuClass = ['about']
    let chevronAngle = 180
    
    const slides = [
        {image: require('../../assets/img/layout/cover/slide_01.jpg'), position: 'center'},
        {image: require('../../assets/img/layout/cover/slide_02.jpg'), position: 'center' },
        {image: require('../../assets/img/layout/cover/slide_03.jpg'), position: 'bottom'}
    ]

    const slideHTML = slides.map((img, key) => {
        return <img key={key} src={img.image} alt="slide_load" />
    })

    const metaSlides = [...slides]

    //random slide for meta tags
    for(let i = metaSlides.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = metaSlides[i]
        metaSlides[i] = metaSlides[j]
        metaSlides[j] = temp
    }


    if(menuState) {
        menuClass.push('aboutClosed')
        chevronAngle = 0
    }

    const toggleMenuView = () => {
        if(breakpoints[currentBreakpoint] < breakpoints.tabletLandscape) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        
        setMenuState(!menuState)
    }

    useEffect(() => {
        const loop = setInterval(() => {
            let nextSlide = (slideState + 1) % slides.length
            setSlideState(nextSlide)
        }, 5000)        

        return () => clearInterval(loop)
    }, [slideState, slides.length])

    return (
        <React.Fragment>
        <HelmetWrapper 
            image={metaSlides[0].image}
            />            
            <section className="home">
                <TransitionGroup component={null}>
                    <CSSTransition
                        key={slideState}
                        appear
                        timeout={1020}
                        classNames={{ ...classes }}>
                            <div className="homeContainer" style={{ backgroundImage: `url(${slides[slideState].image})`, backgroundPosition: slides[slideState].position  }}></div>
                        </CSSTransition>
                    </TransitionGroup>
                
                <div className={menuClass.join(' ')}>
                    <h1 onClick={toggleMenuView}>WELCOME <FontAwesomeIcon icon={faAngleDoubleUp} rotation={chevronAngle} className="chevron" /></h1>
                    <p>Welcome to CYM Lighting, a full service production lighting company with the ability to aid you in all areas of your event. 
                        Everything from transportation to final design, our staff is here to accommodate your needs. 
                        Our staff of designers has many years of experience in corporate events, television broadcast and live entertainment.<br /><br />

                        As our industry changes we are committed to changing at the same rapid pace in our inventory and designs</p>
                    {/* <div className="btn">
                        <span>MORE INFO <FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </div>                     */}
                </div>
                <div style={{display: 'none'}}>
                    {slideHTML}
                </div>
            </section>
        </React.Fragment>

    )
}

export default withBreakpoints(Home)