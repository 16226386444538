import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter  } from 'react-router-dom'
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'
import ReactBreakpoints from 'react-breakpoints'
import HelmetWrapper from './HOC/Helmet/HelmetWrapper'

import Home from './containers/Home/Home'
import Projects from './containers/Projects/Projects'
import Inventory from './containers/Inventory/Inventory'
import Contact from './containers/Contact/Contact'
import Layout from './components/UI/Layout/Layout'
import Store from './store/Store'

import Loading from './components/UI/Loading/Loading'

import './components/ContentWrapper/ContentWrapper.scss'
import './App.scss'

const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
  }

const App = ({location}) => {
    const path = location.pathname.split('/')
    const pathKey = path[1]
    let transitionEnded = false

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
      }, [])    

  return (
    <>
    <HelmetWrapper 
        title="CYM Lighting"
        description="Full Service Production Lighting"
        image={require('./assets/img/layout/cover/slide_01.jpg')}
        />
    <Transition
        in={loading}
        unmountOnExit={true}
        timeout={1000}>
    {state => (        
        <Loading stage={state} />
    )}
            
    </Transition>  

    {loading === false ? (
    <Store>
        <ReactBreakpoints breakpoints={breakpoints}>
            <Layout>               
                <TransitionGroup component={null}>
                    <CSSTransition
                        key={pathKey}
                        appear
                        addEndListener={() => {transitionEnded = true}}
                        onEnter={() => { document.body.style.overflow = "hidden" }}
                        onEntered={() => {
                            window.scrollTo(0, 0)
                            document.body.style.overflow = "auto"
                            
                        }}
                        timeout={{
                            appear: 1500,
                            enter: 600,
                            exit: 500,
                           }}
                        classNames={{
                            appear: 'apearEnter',
                            enter: 'enter',
                            enterActive: 'enterActive',
                            enterDone: 'enterDone',
                            exit: 'exit',
                            exitActive: 'exitActive',
                            exitDone: 'exitDone'
                        }}>               
                        <Switch location={location}>
                            <Route path="/contact" component={Contact} />
                            <Route path="/inventory" component={Inventory} />
                            <Route path="/projects/:section?/:project?" render={(props) => <Projects {...props} transitionEnded={transitionEnded}  />} />
                            
                            <Route path="/" exact component={Home} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </Layout>
        </ReactBreakpoints>
    </Store>
    ) : null }
    </>    
  )
  
}

export default withRouter(App);
